import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ConfiguratorHeader } from '../../../components/headers';
import { SHOPIFY_PRODUCT_PRICE_CENTS } from '../../../config';
import { AppStates } from '../../../types';

import { AddToCartButton } from '../../cart/AddToCartButton';
import {
  addUpsell,
  getChosenUpsellsPrice,
  selectAvailableUpsellItems,
  selectChosenUpsellItems
} from '../store/upsellsSlice';

import { UpsellSelector } from './UpsellSelector';
import { BackToConfiguratorButton } from '.';
import { ProductPreviewComponent } from '../../preview';
import { useConfiguratorDispatch } from '../../../store';
import { useMaterials } from '../../clothingType/Materials.hooks';
import { CartItemState } from '../../app/Configurator';

interface UpsellsProps {
  isReadyForCart: boolean;
  isMobileView: boolean;
  preSelectedUpsells: CartItemState[];
  cartItemId?: string;
  setAppState: Dispatch<SetStateAction<AppStates>>;
}

export function Upsells({
  isReadyForCart,
  setAppState,
  isMobileView,
  cartItemId,
  preSelectedUpsells
}: UpsellsProps) {
  const dispatch = useConfiguratorDispatch();
  const upsells = useSelector(selectAvailableUpsellItems);
  const chosenUpsellItems = useSelector(selectChosenUpsellItems);
  const upsellPrices = useSelector(getChosenUpsellsPrice);
  const { materials, isLoading } = useMaterials();
  const totalPrice = parseFloat(SHOPIFY_PRODUCT_PRICE_CENTS) + upsellPrices;
  const [preSelectedUsed, setPreSelectedUsed] = useState(false);

  useEffect(() => {
    if (isLoading || preSelectedUsed) {
      return;
    }
    setPreSelectedUsed(true);
    for (const item of preSelectedUpsells) {
      const upsell = upsells.find(
        upsell => upsell.product_id === item.product_id
      );
      const material = materials.find(
        material => material?.material_id === item.label_slots?.[0]?.material_id
      );
      if (
        !upsell ||
        !material ||
        chosenUpsellItems.some(
          upsell => upsell.upsell.product_id === item.product_id
        )
      ) {
        continue;
      }
      dispatch(
        addUpsell({
          cartItemId: item.cart_item_id,
          upsell,
          material
        })
      );
    }
  }, [
    chosenUpsellItems,
    preSelectedUpsells,
    isLoading,
    upsells,
    materials,
    dispatch,
    preSelectedUsed
  ]);

  return (
    <>
      <div className="flex flex-col gap-5">
        <ConfiguratorHeader
          title="Frequently Bought Together"
          priceCents={totalPrice}
        />
        {isMobileView && (
          <div className="h-0 w-0 overflow-hidden">
            <ProductPreviewComponent />
          </div>
        )}
        {upsells?.map(upsell => (
          <UpsellSelector key={upsell.product_id} upsell={upsell} />
        ))}
      </div>
      <div className="flex gap-3">
        <BackToConfiguratorButton
          style={{ flexGrow: 1 }}
          setAppState={setAppState}
        />

        <AddToCartButton
          style={{ flexGrow: 1 }}
          setAppState={setAppState}
          isReadyForCart={isReadyForCart}
          cartItemId={cartItemId}
          preSelectedUpsells={preSelectedUpsells}
        />
      </div>
    </>
  );
}
