import { useEffect, useState, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import clsx from 'clsx';
import { isEqual } from 'lodash';

import { useConfiguratorDispatch, useRootSelector } from '../../store';
import { SHOPIFY_PRODUCT_TAG, SHOPIFY_PRODUCT_PRICE_CENTS } from '../../config';
import { AppStates, ItemProperties } from '../../types';
import { CartErrors, getCartErrors } from '../../utils/getCartErrors';

import { ProductPreviewComponent } from '../preview';
import { useGetProductQuery, useGetUpsellsQuery } from '../api';
import { Upsells, processUpsells, setAvailableUpsellItems } from '../upsells';
import {
  resetItem,
  selectItemProperties,
  setFont,
  setIcon,
  setMaterial,
  setPalette,
  setProductId,
  setProductTag,
  setProductVdpCode,
  setSlotField,
  setSlots,
  setStyle,
  syncFields,
  updateLabelShape,
  updateStyle
} from '../item/store/itemSlice';
import { setTextField } from '../accordion/accordionSlice';
import { getApiCartItem } from '../cart/utils';
import { setPageFonts } from '../fonts';

import { AddedToCart, CustomerConfigurator } from './components';
import './App.css';
import { parseLegacyProductCode } from '../../utils/parseLegacyProductCode';

const { CONFIGURING, SHOW_UPSELLS, ADDED_TO_CART } = AppStates;

type LabelSlot = {
  label_id: string;
  label_variant_id: string;
  material_id: string;
  slot_id: string;
};

type TextField = {
  text_field_id: string;
  value: string;
};

export type CartItemState = {
  product_id?: string;
  cart_item_id?: string;
  shopify_key?: string;
  style_id: string;
  palette_id: string;
  font_id: string;
  icon_id: string;
  text_fields: TextField[];
  label_slots: LabelSlot[];
};

type CustomizerAppParams = {
  admin?: boolean;
  product_tag?: string;
  show?: boolean;
  priceCents?: number;
  addToCartButton?: boolean;
  state?: CartItemState;
  cartItemId?: string;
  updateErrors?: (errors: CartErrors | undefined) => void;
  updateModified?: (mod: boolean) => void;
};

function Configurator({
  admin = false,
  product_tag,
  show,
  state,
  cartItemId,
  priceCents,
  addToCartButton = true,
  updateErrors,
  updateModified
}: CustomizerAppParams) {
  const [showApp, setShowApp] = useState(show || false);
  const dispatch = useConfiguratorDispatch();
  const isMobileView = useMediaQuery({ maxWidth: 800 });
  const [appState, setAppState] = useState<AppStates>(CONFIGURING);
  const item = useRootSelector(selectItemProperties);
  const [preSelectedUpsells, setPreSelectedUpsells] = useState<CartItemState[]>(
    []
  );
  const [legacyVdpCodes] = useState<
    | undefined
    | {
        style: string;
        palette: string;
        icon: string;
        font: string;
        material: string;
        packShape: string;
        shoe: string;
        name: string;
        line1: string;
        line2: string;
        line3: string;
        line4: string;
        line5: string;
        alert: string;
        number: string;
        tagline: string;
      }
  >(parseLegacyProductCode());

  const shopifyProductTag = product_tag ?? SHOPIFY_PRODUCT_TAG;
  // Handle responses to changes to the Product ID and its query.
  // Every time this value _changes_, set the defaults and load the fonts.
  const {
    data: apiProduct,
    isSuccess,
    isError,
    isLoading
  } = useGetProductQuery(shopifyProductTag);
  state = apiProduct ? state : undefined;

  useEffect(() => {
    const productIdExists = Boolean(item.productId);
    if (productIdExists) {
      dispatch(resetItem());
    }

    const handleShowReactApp = () => {
      setShowApp(true);

      if (!apiProduct) return;

      dispatch(setProductTag(shopifyProductTag));
      dispatch(setProductId(apiProduct.product_id));
      dispatch(setProductVdpCode(apiProduct.vdpcode));
      setPageFonts(apiProduct.fonts);

      const productStyles = apiProduct.styles || [];
      const [defaultStyle] = productStyles;
      let selectedStyle = defaultStyle;

      if (legacyVdpCodes?.style) {
        const matchingStyle = productStyles.find(
          style => style.vdpcode === legacyVdpCodes?.style
        );

        selectedStyle = matchingStyle || defaultStyle;
      }

      if (selectedStyle) {
        const selectedSwatches = window.Configurator?.selectedSwatches;
        dispatch(updateStyle(selectedStyle, selectedSwatches));
      }

      if (legacyVdpCodes?.palette) {
        const matchingPalette = apiProduct.palettes.find(
          palette => palette.vdpcode === legacyVdpCodes?.palette
        );

        if (matchingPalette) dispatch(setPalette(matchingPalette));
      }

      if (legacyVdpCodes?.icon) {
        const matchingIcon = apiProduct.icons.find(
          icon => icon.vdpcode === legacyVdpCodes?.icon
        );

        if (matchingIcon) dispatch(setIcon(matchingIcon));
      }

      if (legacyVdpCodes?.font) {
        const matchingFont = apiProduct.fonts.find(
          font => font.vdpcode === legacyVdpCodes?.font
        );

        if (matchingFont) dispatch(setFont(matchingFont));
      }

      if (legacyVdpCodes?.material) {
        const matchingMaterial = apiProduct.materials.find(
          material => material.vdpcode === legacyVdpCodes?.material
        );

        if (matchingMaterial) dispatch(setMaterial(matchingMaterial));
      }

      const labelSlots = apiProduct.label_slots || [];

      for (const slot of labelSlots) {
        const validLabelIds = slot.slot_options.map(option => option.label_id);

        const validLabels = apiProduct.labels.filter(label =>
          validLabelIds.includes(label.label_id)
        );

        let variant;

        if (legacyVdpCodes?.packShape && legacyVdpCodes?.packShape !== 'ZZZ') {
          variant = validLabels
            .flatMap(label => label.variants)
            .find(variant => variant.vdpcode === legacyVdpCodes.packShape);

          if (variant) {
            dispatch(
              setSlotField({
                slotId: slot.product_label_slot_id,
                field: 'variantId',
                value: variant.label_variant_id
              })
            );
            continue; // Move to the next slot if a variant is found for packShape
          }
        }

        if (legacyVdpCodes?.shoe && legacyVdpCodes?.shoe !== 'Z') {
          variant = validLabels
            .flatMap(label => label.variants)
            .find(variant => variant.vdpcode === legacyVdpCodes.shoe);

          if (variant) {
            dispatch(
              setSlotField({
                slotId: slot.product_label_slot_id,
                field: 'variantId',
                value: variant.label_variant_id
              })
            );
          }
        }
      }

      const variants = apiProduct.labels.flatMap(label => label.variants);
      const textFields = variants.flatMap(variant => variant.text_fields);

      if (legacyVdpCodes?.name) {
        const nameField = textFields.find(f => f.display_name == 'First Name');
        const fieldId = nameField?.text_field_id || '';
        const text = legacyVdpCodes.name;
        dispatch(setTextField({ fieldId, text }));
        dispatch(syncFields());
      }

      if (legacyVdpCodes?.line1) {
        const infoField = textFields.find(f => f.display_name == 'Line 1');
        const fieldId = infoField?.text_field_id || '';
        const text = legacyVdpCodes.line1;
        dispatch(setTextField({ fieldId, text }));
        dispatch(syncFields());
      }

      if (legacyVdpCodes?.line2) {
        const infoField = textFields.find(f => f.display_name == 'Line 2');
        const fieldId = infoField?.text_field_id || '';
        const text = legacyVdpCodes.line2;
        dispatch(setTextField({ fieldId, text }));
        dispatch(syncFields());
      }

      if (legacyVdpCodes?.line3) {
        const infoField = textFields.find(f => f.display_name == 'Line 3');
        const fieldId = infoField?.text_field_id || '';
        const text = legacyVdpCodes.line3;
        dispatch(setTextField({ fieldId, text }));
        dispatch(syncFields());
      }

      if (legacyVdpCodes?.line4) {
        const infoField = textFields.find(f => f.display_name == 'Line 4');
        const fieldId = infoField?.text_field_id || '';
        const text = legacyVdpCodes.line4;
        dispatch(setTextField({ fieldId, text }));
        dispatch(syncFields());
      }

      if (legacyVdpCodes?.line5) {
        const infoField = textFields.find(f => f.display_name == 'Line 5');
        const fieldId = infoField?.text_field_id || '';
        const text = legacyVdpCodes.line5;
        dispatch(setTextField({ fieldId, text }));
        dispatch(syncFields());
      }

      if (legacyVdpCodes?.alert) {
        const alertField = textFields.find(f => f.display_name == 'Alert');
        const fieldId = alertField?.text_field_id || '';
        const text = legacyVdpCodes.alert;
        dispatch(setTextField({ fieldId, text }));
        dispatch(syncFields());
      }

      if (legacyVdpCodes?.number) {
        const numberField = textFields.find(f => f.display_name == 'Number');
        const fieldId = numberField?.text_field_id || '';
        const text = legacyVdpCodes.number;
        dispatch(setTextField({ fieldId, text }));
        dispatch(syncFields());
      }

      if (legacyVdpCodes?.tagline) {
        const taglineField = textFields.find(f => f.display_name == 'Tagline');
        const fieldId = taglineField?.text_field_id || '';
        const text = legacyVdpCodes.tagline;
        dispatch(setTextField({ fieldId, text }));
        dispatch(syncFields());
      }
    };

    if (show) {
      handleShowReactApp();
    } else {
      document.addEventListener('showReactApp', handleShowReactApp, {
        once: true
      });

      return () => {
        document.removeEventListener('showReactApp', handleShowReactApp);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiProduct, dispatch, shopifyProductTag, show]);

  // load upsells from the API and hydrate them with Shopify data.
  const { data: apiUpsells, isLoading: upsellsLoading } = useGetUpsellsQuery(
    apiProduct?.product_id || '',
    { skip: !apiProduct }
  );

  useEffect(() => {
    if (apiUpsells) {
      const processedUpsells = processUpsells(apiUpsells, item);
      dispatch(setAvailableUpsellItems(processedUpsells));
    }
  }, [dispatch, apiUpsells, item]);

  const errors = useMemo(() => {
    if (!apiProduct) return undefined;
    const theErrors = getCartErrors(item, apiProduct);
    updateErrors?.(theErrors);
    return theErrors;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, apiProduct]);

  useEffect(() => {
    if (updateModified && state) {
      const currentItemState = castItemPropertiesToCartItemState(item);
      const isModified = !isEqual(state, currentItemState);
      updateModified(isModified);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess) {
        const event = new CustomEvent('loaded', { detail: true });
        document.dispatchEvent(event);
      }
      if (isError) {
        const event = new CustomEvent('loaded', { detail: false });
        document.dispatchEvent(event);
      }
    }
  }, [isLoading, isSuccess, isError]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const upsellsParam = urlParams.get('upsells') || '';
    const upsellEntries = upsellsParam.split('-');
    const validUpsellEntries = upsellEntries.filter(entry => entry);

    const upsellItems = validUpsellEntries.map(entry => {
      const [shopifyKey, cartItemId] = entry.split('=');
      return { shopifyKey, cartItemId };
    });

    const fetchUpsellData = async () => {
      const upsellDataPromises = upsellItems.map(async upsellItem => {
        const fetchedUpsellData = await getApiCartItem(upsellItem.cartItemId);
        return {
          ...upsellItem,
          ...fetchedUpsellData
        };
      });
      const preSelectedUpsellsData = await Promise.all(upsellDataPromises);
      setPreSelectedUpsells(preSelectedUpsellsData);
    };

    fetchUpsellData();
  }, []);

  // If the state is passed in, set the item properties to the state.
  useEffect(() => {
    if (!state || !apiProduct) return;
    const style = apiProduct.styles.find(
      style => state?.style_id === style.style_id
    );
    if (style) {
      dispatch(setStyle(style));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.style_id]);

  useEffect(() => {
    if (!state || !apiProduct) return;
    const palette = apiProduct.palettes.find(
      palette => state?.palette_id === palette.palette_id
    );
    if (palette) {
      dispatch(setPalette(palette));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.palette_id]);

  useEffect(() => {
    if (!state || !apiProduct) return;
    const font = apiProduct.fonts.find(font => state?.font_id === font.font_id);
    if (font) {
      dispatch(setFont(font));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.font_id]);

  useEffect(() => {
    if (!state || !apiProduct) return;
    const icon = apiProduct.icons.find(icon => state?.icon_id === icon.icon_id);
    if (icon) {
      dispatch(setIcon(icon));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.font_id]);

  useEffect(() => {
    if (!state) return;
    state.text_fields.forEach(field => {
      dispatch(
        setTextField({ fieldId: field.text_field_id, text: field.value })
      );
    });
    dispatch(syncFields());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.text_fields]);

  useEffect(() => {
    if (!state || !apiProduct) return;

    const usedMaterialIds = new Set(
      state.label_slots.map(slot => slot.material_id)
    );

    let selectedMaterialId: string | undefined;

    if (usedMaterialIds.size === 1) {
      selectedMaterialId = usedMaterialIds.values().next().value;
    } else {
      for (const label of apiProduct.labels) {
        const labelHasMultipleMaterialOptions = label.material_ids.length > 1;
        if (!labelHasMultipleMaterialOptions) continue;

        const matchingSlot = state.label_slots.find(
          slot => slot.label_id === label.label_id
        );

        const isValidSlot =
          matchingSlot && usedMaterialIds.has(matchingSlot.material_id);

        if (isValidSlot) {
          selectedMaterialId = matchingSlot.material_id;
          break;
        }
      }

      if (!selectedMaterialId) {
        selectedMaterialId = usedMaterialIds.values().next().value;
      }
    }

    const selectedMaterial = apiProduct.materials.find(
      material => material.material_id === selectedMaterialId
    );

    if (selectedMaterial) {
      dispatch(setMaterial(selectedMaterial));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.label_slots]);

  useEffect(() => {
    if (!state) return;
    state.label_slots.forEach(field => {
      dispatch(
        setSlots({
          slotId: field.slot_id,
          value: {
            labelId: field.label_id,
            variantId: field.label_variant_id,
            materialId: field.material_id
          }
        })
      );
    });
    if (state.label_slots.length == 1) {
      dispatch(updateLabelShape(state.label_slots[0].label_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.label_slots]);

  if (!showApp) return <></>;

  return (
    <div className={clsx('relative z-[101] flex w-full justify-center')}>
      <div
        style={{ fontFamily: 'Poppins, sans-serif' }}
        className="flex grow items-stretch md:mx-8"
      >
        {!isMobileView && (
          <div className="box-border flex h-screen max-h-[600px] w-2/3 grow justify-center p-10	">
            <ProductPreviewComponent productTag={shopifyProductTag} />
          </div>
        )}

        <div className="md:max-w-1/3 flex grow flex-col items-stretch md:w-1/3 md:min-w-[476px]">
          {appState === CONFIGURING && (
            <CustomerConfigurator
              admin={admin}
              productTag={shopifyProductTag}
              isMobileView={isMobileView}
              priceCents={priceCents || SHOPIFY_PRODUCT_PRICE_CENTS}
              setAppState={setAppState}
              errors={errors}
              loading={upsellsLoading}
              addToCartButton={addToCartButton}
              cartItemId={cartItemId}
            />
          )}

          {appState === SHOW_UPSELLS && (
            <Upsells
              isReadyForCart={!errors}
              setAppState={setAppState}
              isMobileView={isMobileView}
              cartItemId={cartItemId}
              preSelectedUpsells={preSelectedUpsells}
            />
          )}

          {appState === ADDED_TO_CART && (
            <AddedToCart productPrice={SHOPIFY_PRODUCT_PRICE_CENTS} />
          )}
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default Configurator;

function castItemPropertiesToCartItemState(item: ItemProperties) {
  return {
    style_id: item.style?.style_id,
    palette_id: item.palette?.palette_id,
    font_id: item.font?.font_id,
    icon_id: item.icon?.icon_id,
    text_fields: Object.keys(item.fields).map(key => ({
      text_field_id: key,
      value: item.fields[key].value
    })),
    label_slots: Object.keys(item.slots).map(key => ({
      slot_id: key,
      label_id: item.slots[key].labelId,
      label_variant_id: item.slots[key].variantId,
      material_id: item.slots[key].materialId
    }))
  };
}
